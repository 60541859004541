import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const billingInfoApi = `${domain}api/v1/dashboard/billing/info`
const invoicesApi = `${domain}api/v1/dashboard/billing/invoices`
const historyApi = `${domain}api/v1/dashboard/billing/history`
const balanceHistoryApi = `${domain}api/v1/dashboard/billing/balance-history`
const paymentMethodApi = `${domain}api/v1/dashboard/billing/payment-method/`
const paymentMethodDeleteApi = (id) => `${paymentMethodApi}${id}/`
const activePurchaseApi = `${domain}api/v1/dashboard/billing/get-purchases/`

export const getBillingInfo = () => interceptor(axios).get(billingInfoApi)
export const saveBillingInfo = (data) => interceptor(axios).post(billingInfoApi, data)
export const getInvoices = (params) => interceptor(axios).get(invoicesApi, { params })
export const getHistory = (params) => interceptor(axios).get(historyApi, { params })
export const getBalanceHistory = (params) => interceptor(axios).get(balanceHistoryApi, { params })
export const getPaymentMethodInfo = () => interceptor(axios).get(paymentMethodApi)
export const deletePaymentMethodApi = (id) => interceptor(axios).delete(paymentMethodDeleteApi(id))
export const getActivePurchasesApi = (params) => interceptor(axios).get(activePurchaseApi, { params })
