<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('activePurchasesTitle') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :is-show-filters="false"
    :license="license"
    :request="request"
    :is-search="false"
    :csv-handler="downloadCsvHandler"
    :sort="'desc'"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table v-else :license="license" :list="list"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'
import { getActivePurchasesApi } from '@/application/services/billing'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVActivePurchases } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ActivePurchasesPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/ActivePurchasesTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'active-purchased.csv',
      formatterCSVActivePurchases,
      {
        price: i18n.t('forms.billing.rows.price'),
        quantity: i18n.t('forms.billing.rows.quantity'),
        status: i18n.t('forms.billing.rows.status'),
        messages: i18n.t('forms.billing.rows.messages'),
        term: i18n.t('forms.billing.rows.term'),
        senderNames: i18n.t('forms.billing.rows.senderNames'),
        paymentMethod: i18n.t('forms.billing.rows.paymentMethod'),
        lastFourDigits: i18n.t('forms.billing.rows.lastFourDigits'),
        expiryDate: i18n.t('forms.billing.rows.expiryDate'),
        nextBillDate: i18n.t('forms.billing.rows.nextBillDate'),
      }
    )
    return {
      license,
      request: getActivePurchasesApi,
      downloadCsvHandler,
    }
  },
}
</script>
